<template>
  <div class="joystick-container">
    
    <div 
      ref="joystick"
      class="joystick"
      :style="style"
      :class="isCircular ? 'is-circular' : 'not-circular'"
      @touchstart="joystickHandleTouchStart"
      @touchmove="joystickHandleTouchMove"
      @touchend="joystickHandleTouchEnd"
    >
      <div class="joystick-center" v-if="isCircular" :style="{ backgroundColor: 'var(--centerColor)' }"></div>

      <div class="joystick-ball"
        :style="{ backgroundColor: 'var(--userColor)' }">
      </div>
      
      <div class="joystick-stick" v-if="!isCircular"></div>
      
      <div v-if="debug" class="debugger">
        {{this.angle}}°<br>
        {{this.buttons}}
      </div>
    </div>
    <div class="jButtons" v-if="buttons > 1">
      <div v-for="n in buttons"
        :key="`btn-${n}`"
        class="button"
        @touchstart="buttonChange(n,true)"
        @touchend="buttonChange(n,false)">
        {{['A','B','C'][n-1]}}
      </div>
    </div>
  </div>
</template>


<script>

// TODO: XY normalizar 
export default {
  props: {
    startingAngle: {
      type: Number,
      default: 90, // -90 Arriba - 0 Derecha - 90 Abajo - 180 Izquierda
    },
    centerColor: {
      type: String,
      default: '#fafafa'
    },
    isCircular: {
      type: Boolean,
      default: true
    },
    debug: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      angle: 180,
      speed: 0,
      radians: 0,
      size: 240,
      halfSize: 120,
      offset: 0,
      isMouseDown: false,
    };
  },

  computed: {
    //Para pasar variables al css, deben asignarse en el estilo inline 
    style() {
      return {
        "--size": this.isCircular ? '21%' : '40%',
        "--speed": `${this.speed}px`,
        "--offcenter": this.isCircular ? '-208%' : `${this.speed}px`,
        "--angle": this.isCircular ? `calc(${this.angle}deg + 90deg)` : `calc(${this.angle}deg - 90deg)`,
        '--centerColor': this.centerColor
      };
    }
  },

  methods: {
    joystickHandleTouchStart(evt) {
      // console.log("Mouse/Touch Down")
      this.$emit("touchStart")
      this.isMouseDown = true;
      this.joystickHandleTouchMove(evt)
    },
    joystickHandleTouchMove(evt) {
      if(!this.isMouseDown) return
      const touch = evt.touches[0];
      if(touch.target.className == "joystick-center") { return} // Circulo central del joystick circular

      const { clientX, clientY } = touch;
      const { offsetLeft, offsetTop } = this.$el;

      // Calculate the cumulative offsets of the element and its ancestors
      let parent = this.$el;
      let absoluteX = clientX - offsetLeft;
      let absoluteY = clientY - offsetTop;
      // Loop parents sumando scrollTop
      while (parent) {
        absoluteY += parent.scrollTop;
        parent = parent.parentElement;
      }

      const x = Math.round(absoluteX - this.halfSize);
      const y = Math.round(absoluteY - this.halfSize);

      this.updatePosition(x, y);
    },
    joystickHandleTouchEnd() {
      // console.log("Mouse/Touch Up")
      this.isMouseDown = false; 
      if (this.isCircular) return
      this.updatePosition(0, 0);
      this.$emit("touchFinish")
    },
    updatePosition(x, y) {
      this.radians = Math.atan2(y, x);
      const angle = Math.round((this.radians * 180) / Math.PI, 4);

      this.angle = angle + (angle > 180 ? -180 : 90);
      this.angle -= 90; //Angle 0 = radians 0 = 15:00 horas

      if (this.isCircular) {
        this.x = Math.cos(this.radians) * this.offset;
        this.y = Math.sin(this.radians) * this.offset;
      } else {
        this.speed = Math.min(Math.round(Math.sqrt(Math.pow(y, 2) + Math.pow(x, 2))), this.offset);
        this.x = this.speed > this.offset ? Math.cos(this.radians) * this.offset : x;
        this.y = this.speed > this.offset ? Math.sin(this.radians) * this.offset : y;
      }
      this.emitAll();
    },
    SetPositionByAngle(angle) {
      let angleRadians = (Math.PI / 180) * angle;
      let x = Math.cos(angleRadians) * this.offset;
      let y = Math.sin(angleRadians) * this.offset;
      this.updatePosition(x, y);
    },
    buttonChange(button,pressed){
      this.$emit("buttonchange", {
        button: button-1,
        pressed: pressed
      })
    },
    emitAll(name = "leverchange") {
      
      this.$emit(name, {
        angle: this.angle,
        radians: this.radians,
        x: this.isCircular ? 0 : this.normalize(this.x, 0, 1),
        y: this.isCircular ? 0 : this.normalize(this.y, 0, 1),
        speed: this.normalize(this.speed, 0, this.halfSize),
      });
    },
    normalize(val, min, max) {
      // console.log(min,max)
      return (val - min) / (max - min);
    },
    resetJoystick(){
      this.size = this.$refs.joystick.offsetWidth;
      this.halfSize = this.size * 0.5;
      this.offset = this.size * 0.375;
      this.speed = 0;
      if(this.isCircular) {
        this.SetPositionByAngle(this.startingAngle)
      } else {
        this.x  = 0;
        this.y = 0;
      }
    }
  },
  mounted() {
    this.resetJoystick();
    window.addEventListener('resize', this.resetJoystick);

    this.emitAll();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resetJoystick);
  }
};
</script>

<style scoped src="./JoyStick.scss" lang="scss"></style>